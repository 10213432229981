"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    value: false,
    path:"",
    currentPath:"",
    
    
    
 };

export const showLoaderSlice = createSlice({
    name: "showLoader",
    initialState,
    reducers: {
        setShowLoader(state, action) {
            state.value = action.payload;
        },
        setPrevPath:(state,action)=>{
            state.path = action.payload;
          },
        setCurrentPath:(state,action)=>{
            state.currentPath = action.payload;
          }
       
    },
});

export default showLoaderSlice.reducer;

export const { setShowLoader , setPrevPath ,setCurrentPath } = showLoaderSlice.actions;

export const selectShowLoader = (state) => state.showLoader.value;
export const selectPrevPath = (state) => state.showLoader.path;
export const selectCurrentPath = (state) => state.showLoader.currentPath;


