import { useCallback } from "react";
import { useSearchParams, usePathname, useRouter } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import {
  unsetAuthUser,
  selectAuthUser,
  setAuthUser,
} from "@/redux/slices/authUserSlice";

import {
  decreaseActiveLineCards,
  selectActiveLineCards,
  unsetAllActiveLineCards,
  increaseActiveLineCards,
  setActiveLineCardValues,
  randomizeAllLineCardValues,
  clearAllLineCardValues,
} from "@/redux/slices/activeLineCardsSlice";
import { selectCsrfToken, setCsrfToken } from "@/redux/slices/csrfTokenSlice";
import { setShowLoader ,setPrevPath,selectPrevPath,setCurrentPath,selectCurrentPath} from "@/redux/slices/showLoaderSlice";
import { showToaster } from "@/redux/slices/showToastSlice";
import {
  selectShowFavouritesNumbers,
  setShowFavouritesIsOpened,
  setShowFavouritesNumbers,
  selectShowFavourites,
  setShowFavouritesContent,
  addShowFavouritesNumbers,
  unsetShowFavouritesNumbers,
  selectActiveFavCards,
  setActiveCardFav,
} from "@/redux/slices/showFavouritesModalSlice";
import {
  setAddFavouritesIsOpened,
  setAddFavouritesNumbers,
  selectAddFavourites,
  setAddFavouritesContent,
} from "@/redux/slices/addFavouritesModalSlice";
import {
  selectReadyStore,
  setReadyStore,
} from "@/redux/slices/readyStoreSlice";
import { selectLocale, setLocale } from "@/redux/slices/localeSlice";
import {
  setCart,
  selectCart,
  emptyCart,
  setPurchaseCartId,
  selectPurchaseCartId,
  setUseAvailableBalance,
} from "@/redux/slices/cartSlice";
import { selectNews } from "@/redux/slices/selectedNewsSlice";
import {
  setTimeOutConfirmationIsOpened,
  selectTimeoutConfirmationUpdateStatus,
  selectTimeoutConfirmation,
  setTimeOutConfirmationUpdateStatus,
} from "@/redux/slices/timeOutConfirmationSlice";
import {
  setSelfExclusionConfirmationIsOpened,
  selectSelfExclusionConfirmationUpdateStatus,
  selectSelfExclusionConfirmation,
  setSelfExclusionConfirmationUpdateStatus,
} from "@/redux/slices/selfExclusionConfirmationSlice";
import {
  setCloseAccountConfirmationIsOpened,
  setCloseAccountConfirmationUpdateStatus,
  selectCloseAccountConfirmation,
  setCloseAccountConfirmationContent,
} from "@/redux/slices/closeAccountConfirmationSlice";
import {
  selectGrandDrawPrice,
  selectGrandDrawDrawDate,
} from "@/redux/slices/grandDrawSlice";
import {
  selectCurrency,
  setCurrency,
  setCurrencyList,
  getCurrencyList,
  setUAECurrency,
  getUAECurrencyDetails,
  setBackToPagePath,
  getBackToPagePath,
} from "@/redux/slices/currencySlice";
import {
  selectShowMobileMenu,
  setShowMobileMenu,
} from "@/redux/slices/showMobileMenuSlice";
import {
  setNonPassportUploadContent,
  setNonPassportUploadIsOpened,
  selectNonPassportUpload,
} from "@/redux/slices/nonPassportUploadSlice";
import { setGrandDraw, setDrawID } from "@/redux/slices/grandDrawSlice";
import { dispatchCounterDetails } from "@/redux/slices/counterSlice";
import {
  setGameGroups,
  selectGameGroups,
} from "@/redux/slices/gameGroupsSlice";
import {
  setCardDetails,
  getCardDetailsSelector,
} from "@/redux/slices/paymentSlice";
import {
  selectBalance,
  setBalance,
  setAddCreditBtnClicked,
  getAddCeditBtnClicked,
} from "@/redux/slices/balanceSlice";
import {
  selectCountry,
  selectIpAddress,
  selectTimezone,
  setCountry,
  setGeolocation,
  setIpAddress,
  setTimezone,
} from "@/redux/slices/geolocationSlice";

/*========== FUNCTIONS ========== */
export function useSelectCountryLocation() {
  return useSelector(selectCountry);
}

export function useSelectTimeZoneLocation() {
  return useSelector(selectTimezone);
}

export function useSelectIpAddressLocation() {
  return useSelector(selectIpAddress);
}

export function useSelectNonPassportUpload() {
  return useSelector(selectNonPassportUpload);
}

export function useSelectGetPaymentmethodCards() {
  return useSelector(getCardDetailsSelector);
}

export function useSelectNews() {
  return useSelector(selectNews);
}

export function useSelectBalance() {
  return useSelector(selectBalance);
}

export function useSelectShowMobileMenu() {
  return useSelector(selectShowMobileMenu);
}

export function useSelectCurrency() {
  return useSelector(selectCurrency);
}

// Getting List of currency
export function useGetCurrencyList() {
  return useSelector(getCurrencyList);
}

//  Get only UAE currency details
export function useGetUAECurrencyList() {
  return useSelector(getUAECurrencyDetails);
}

// Get path to login
export function useGetPathToLogin() {
  return useSelector(getBackToPagePath);
}

export function useSelectGrandDrawDate() {
  return useSelector(selectGrandDrawDrawDate);
}

export function useSelectGrandDrawPrice() {
  return useSelector(selectGrandDrawPrice);
}

export function useSelectCart() {
  return useSelector(selectCart);
}

export function useSelectGameGroups() {
  return useSelector(selectGameGroups);
}

export function useSelectTimeOutConfirmation() {
  return useSelector(selectTimeoutConfirmation);
}

export function useSelectSelfExclusionConfirmation() {
  return useSelector(selectSelfExclusionConfirmation);
}

export function useSelectCloseAccountConfirmation() {
  return useSelector(selectCloseAccountConfirmation);
}

export function useSelectTimeoutConfirmationUpdateStatus() {
  return useSelector(selectTimeoutConfirmationUpdateStatus);
}

export function useSelectSelfExclusionConfirmationUpdateStatus() {
  return useSelector(selectSelfExclusionConfirmationUpdateStatus);
}

// Add Credit Btn Click Hook
export function useGetAddCeditBtnClicked() {
  return useSelector(getAddCeditBtnClicked);
}

// Add Credit Btn Click Hook
export function useSetAddCreditBtnClicked() {
  const dispatch = useDispatch();

  const action = (value = null) => {
    dispatch(setAddCreditBtnClicked(value));
  };

  return action;
}

export function useDispatchSetCreditCardPaymentMethods() {
  const dispatch = useDispatch();

  const action = (value = false) => {
    dispatch(setCardDetails(value));
  };

  return action;
}

export function useDispatchSetCsrfToken() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = "") => {
      dispatch(setCsrfToken(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetUseAvailableBalance() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = "") => {
      dispatch(setUseAvailableBalance(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetReadyStore() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = false) => {
      dispatch(setReadyStore(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetCounterDetails() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = "") => {
      dispatch(dispatchCounterDetails(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetDrawID() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = 0) => {
      dispatch(setDrawID(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetGrandDraw() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = {}) => {
      dispatch(setGrandDraw(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetGeolocation() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = { country: "", ipAddress: "", timezone: "" }) => {
      dispatch(setGeolocation(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetCountry() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = "") => {
      dispatch(setCountry(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetIpAddress() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = "") => {
      dispatch(setIpAddress(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetTimezone() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = "") => {
      dispatch(setTimezone(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetLocale() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = "en") => {
      dispatch(setLocale(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetNonPassportUploadIsOpened() {
  const dispatch = useDispatch();

  const action = (value = false) => {
    dispatch(setNonPassportUploadIsOpened(value));
  };

  return action;
}

export function useDispatchSetNonPassportUploadContent() {
  const dispatch = useDispatch();

  const action = (value = {}) => {
    dispatch(setNonPassportUploadContent(value));
  };

  return action;
}

export function useDispatchSetCurrency() {
  const dispatch = useDispatch();

  const action = (value = {}) => {
    dispatch(setCurrency(value));
  };

  return action;
}
export function useDispatchSetUAECurrency() {
  const dispatch = useDispatch();

  const action = (value = {}) => {
    dispatch(setUAECurrency(value));
  };

  return action;
}
// pathToLogin
export function useDispatchBackToPagePath() {
  const dispatch = useDispatch();

  const action = (value = {}) => {
    dispatch(setBackToPagePath(value));
  };

  return action;
}
export function useDispatchSetCurrencyList() {
  const dispatch = useDispatch();

  const action = (value = {}) => {
    dispatch(setCurrencyList(value));
  };

  return action;
}

export function useDispatchSetShowMobileMenu() {
  const dispatch = useDispatch();

  const action = (value = false) => {
    dispatch(setShowMobileMenu(value));
  };

  return action;
}

export function useDispatchShowTimeOutConfirmation() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = false) => {
      dispatch(setTimeOutConfirmationIsOpened(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchShowSelfExclusionConfirmation() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = false) => {
      dispatch(setSelfExclusionConfirmationIsOpened(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchShowCloseAccountConfirmation() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = false) => {
      dispatch(setCloseAccountConfirmationIsOpened(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetSelfExclusionConfirmationUpdateStatus() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = "") => {
      dispatch(setSelfExclusionConfirmationUpdateStatus(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetTimeOutConfirmationUpdateStatus() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = "") => {
      dispatch(setTimeOutConfirmationUpdateStatus(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetCloseAccountConfirmationContent() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = {}) => {
      dispatch(setCloseAccountConfirmationContent(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetCloseAccountConfirmationUpdateStatus() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = "") => {
      dispatch(setCloseAccountConfirmationUpdateStatus(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetCart() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = []) => {
      dispatch(setCart(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetPurchaseCartId() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = null) => {
      dispatch(setPurchaseCartId(value));
    },
    [dispatch]
  );

  return action;
}
export function useDispatchSetGameGroups() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = []) => {
      dispatch(setGameGroups(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetActiveLineCardValues() {
  const dispatch = useDispatch();

  const action = (value = {}) => {
    dispatch(setActiveLineCardValues(value));
  };

  return action;
}

export function useDispatchRandomizeAllLineCardValues() {
  const dispatch = useDispatch();

  const action = (value = {}) => {
    dispatch(randomizeAllLineCardValues(value));
  };

  return action;
}

export function useDispatchClearAllLineCardValues() {
  const dispatch = useDispatch();

  const action = (value = {}) => {
    dispatch(clearAllLineCardValues(value));
  };

  return action;
}

export function useDispatchIncreaseActiveLineCards() {
  const dispatch = useDispatch();

  const action = () => {
    dispatch(increaseActiveLineCards());
  };

  return action;
}

export function useDispatchDecreaseActiveLineCards() {
  const dispatch = useDispatch();

  const action = () => {
    dispatch(decreaseActiveLineCards());
  };

  return action;
}

export function useDispatchUnsetAllActiveLineCards() {
  const dispatch = useDispatch();

  const action = () => {
    dispatch(unsetAllActiveLineCards());
  };

  return action;
}

export function useSelectPurchaseCartId() {
  return useSelector(selectPurchaseCartId);
}
export function useSelectShowFavourites() {
  return useSelector(selectShowFavourites);
}
// select prevpath
export function useSelectPrevPath() {
    return useSelector(selectPrevPath);
}

// select currentPath
export function useSelectCurrentPath() {
    return useSelector(selectCurrentPath);
}
export function useSelectAddFavourites() {
  return useSelector(selectAddFavourites);
}

export function useSelectShowFavouritesNumbers() {
  return useSelector(selectShowFavouritesNumbers);
}

export function useSelectLocale() {
  const locale = useSelector(selectLocale);
  return locale.toLowerCase();
}

export function useSelectReadyStore() {
  return useSelector(selectReadyStore);
}

export function useSelectCsrfToken() {
  return useSelector(selectCsrfToken);
}

export function useSelectActiveLineCards() {
  return useSelector(selectActiveLineCards);
}
export function useSelectActiveCardFav() {
  return useSelector(selectActiveFavCards);
}
export function useSelectAuthUser() {
  return useSelector(selectAuthUser);
}

export function useDispatchSetShowFavouritesNumbers() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = []) => {
      dispatch(setShowFavouritesNumbers(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchAddShowFavouritesNumbers() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = {}) => {
      dispatch(addShowFavouritesNumbers(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetActiveCardFav() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = {}) => {
      dispatch(setActiveCardFav(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetShowFavouritesContent() {
  const dispatch = useDispatch();

  const action = (value = {}) => {
    dispatch(setShowFavouritesContent(value));
  };

  return action;
}

export function useDispatchShowFavouritesModal() {
  const dispatch = useDispatch();

  const show = (value = {}) => {
    dispatch(setShowFavouritesIsOpened(value));
  };

  return show;
}

export function useDispatchSetAddFavouritesContent() {
  const dispatch = useDispatch();

  const action = (value = {}) => {
    dispatch(setAddFavouritesContent(value));
  };

  return action;
}

export function useDispatchAddFavouritesNumbers() {
  const dispatch = useDispatch();

  const action = (value = []) => {
    dispatch(setAddFavouritesNumbers(value));
  };

  return action;
}

export function useDispatchAddFavouritesModal() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = false) => {
      dispatch(setAddFavouritesIsOpened(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchShowLoader() {
  const dispatch = useDispatch();

  const showLoader = useCallback(
    (show = false) => {
      dispatch(setShowLoader(show));
    },
    [dispatch]
  );

  return showLoader;
}

export function useDispatchPrevPath() {
    const dispatch = useDispatch();

    const prevPath = useCallback(
        (show = "") => {
            dispatch(setPrevPath(show));
        },
        [dispatch]
    );

    return prevPath;
}
// CurrentPath
export function useDispatchCurrentPath() {
    const dispatch = useDispatch();

    const currentPath = useCallback(
        (show = "") => {
            dispatch(setCurrentPath(show));
        },
        [dispatch]
    );

    return currentPath;
}
export function useDispatchShowToaster() {
  const dispatch = useDispatch();

  const toastLoad = useCallback(
    (message = "") => {
      dispatch(showToaster(message));
    },
    [dispatch]
  );

  return toastLoad;
}

export function useDispatchSetAuthUser() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = {}) => {
      dispatch(setAuthUser(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchSetBalance() {
  const dispatch = useDispatch();

  const action = useCallback(
    (value = {}) => {
      dispatch(setBalance(value));
    },
    [dispatch]
  );

  return action;
}

export function useDispatchLogout() {
  const dispatch = useDispatch();

  const logoutUser = useCallback(() => {
    dispatch(unsetAuthUser({}));
    dispatch(setBalance({}));
    dispatch(unsetShowFavouritesNumbers());
    dispatch(setTimeOutConfirmationUpdateStatus(""));
    dispatch(setSelfExclusionConfirmationUpdateStatus(""));
  }, [dispatch]);

  return logoutUser;
}

export function useGetSearchParams(key = "") {
  const searchParams = useSearchParams();

  return key ? searchParams.get(key) || "" : "";
}

export function useGetPathName() {
  const pathName = usePathname();
  return pathName;
}

export function useRedirect() {
  const router = useRouter();

  const action = useCallback(
    (url = "/#") => {
      if (!url) url = "/#";
      router.push(url);
    },
    [router]
  );

  return action;
}
